<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>

          <!-- Type-->
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>{{ msg("Client") }}</label>
            <b-form-input
                v-model="filter.like.zip"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>{{ msg('Status') }}</label>
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="filter.in.status"
                :options="ticketStatus"
                class="w-100"
                label="text"
                :reduce="(status) => status.value"
                multiple
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Tickets') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.like.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
                <b-button disabled variant="primary" @click="onTicketSelect(0)">
                  <span class="text-nowrap">{{ msg('New Ticket') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="ticket-table"
            striped
            hover
            responsive
            v-bind:items="tickets"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>


          <template #cell(creationDate)="data">
            {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
          </template>


          <template #cell(clientId)="data">
            <div>
              <b-link v-if="clients[data.value]!=null" @click="onClientSelect(clients[data.value].id)">{{ clients[data.value].firstname }}
                {{ clients[data.value].lastname }}
              </b-link>
            </div>

          </template>

          <template #cell(status)="data" class="p-0">
            <b-badge :variant="statusVariants[data.value]">{{ data.value }}</b-badge>
          </template>

        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Tickets',
  components: {

  },
  data() {
    return {
      loading: true,
      amount: 10,
      tickets: [],
      clients: {},
      fields: [],
      filter: {
        like: {search: ''},
        eq: {},
        lte: {},
        gte: {},
        in: {status: []}
      },
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
      statusVariants:
          {"NEW": "danger", "DONE": "success"},

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {ticket: false},
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    file: {
      handler() {
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('ticket', ['allTicketStatus']),

    ticketStatus() {
      return this.allTicketStatus.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    alltickets() {
      return this.tickets
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('ticket', ['getAllTickets', 'copySuccess']),
    ...mapActions('clients', ['getClients']),

    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      this.fields = [
        {
          key: 'firstname',
          label:  $this.msg('firstname'),
          sortable: true
        },

        {
          key: 'lastname',
          label:  $this.msg('firstname'),
          sortable: true
        },

        {
          key: 'phone',
          label:  $this.msg('mobile'),
          sortable: true
        },

{
          key: 'email',
          label:  $this.msg('email'),
          sortable: true
        },


        {
          key: 'clientId',
          label: $this.msg('client'),
          sortable: false
        },
        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true
        },
        {
          key: 'creationDate',
          label: $this.msg('creationDate'),
          sortable: true
        },

      ]
    },

    copyId(id) {
      console.log("the id copied was: ", id)
    },

    refresh() {
      let $this = this
      this.loading = true;
      this.getAllTickets({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(this.onTicketsRetrieve, (error) => $this.error = error)

    },
    onTicketsRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.tickets = data["Tickets"];
      this.loadClients();

      this.loading = false;
    },
    loadClients() {
      let $this = this;
      let clients = this.tickets.map(ticket => ticket.clientId)
      this.getClients({filter: {ids: clients}, amountPerPage: 9999}).then(({clients}) => {
        $this.clients = clients.toObject("id");
      })
    },
    onTicketSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.ticket = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(ticket) {
      this.$router.push(`/ticket/edit/${ticket.id}`);
    },
    submitTicket() {
      this.$root.$emit('crm::submitTicket')
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.ticket = false;
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
  }
}
</script>

<style>

</style>
